// router.js
import Vue from 'vue' // 引入Vue
import VueRouter from 'vue-router' // 引入VueRouter插件

Vue.use(VueRouter) // 初始化VueRouter插件

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/components/datav/index.vue')
    },
    {
      path: '/allot',
      name: 'allot',
      component: () => import('@/components/allot/index.vue')
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('@/components/user/index.vue')
    },
    {
      path: '/goal',
      name: 'goal',
      component: () => import('@/components/goal/index.vue')
    },
    {
      path: '/grower',
      name: 'grower',
      component: () => import('@/components/grower/index.vue')
    },
    {
      path: '/service',
      name: 'grower',
      component: () => import('@/components/service/index.vue')
    }
    // ...其他路由
  ]
});

export default router